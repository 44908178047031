
import { Vue, Options } from "vue-class-component";
import copy from "copy-text-to-clipboard";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import BannerCashback from "./program-cashback-banner.vue";

@Options({
  components: {
    BannerCashback
  }
})
export default class ModalTabTopupVa extends Vue {
  created() {
    BalanceController.getListBankVirtualAccount();
  }

  // filter status
  statusSelect = false;
  get banks() {
    if (BalanceController.listBankVirtualAccount?.data.length === 0) {
      return [
        {
          name: "Pilih Bank",
          value: 0
        }
      ];
    }
    return BalanceController.listBankVirtualAccount?.data.map(e => {
      return {
        name: e.label,
        value: e.id
      };
    });
  }
  get detailSelectedBankVirtualAccount() {
    if (!this.selectedBankVirtualAccount) {
      return null;
    }
    return BalanceController.listBankVirtualAccount?.data.find(e => {
      return e.id === this.selectedBankVirtualAccount.value;
    });
  }
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  get selectedBankVirtualAccount() {
    return BalanceController.selectedBankVirtualAccount;
  }
  onSelectMethodBank(value: any) {
    BalanceController.setSelectedBankVirtualAccount(value);
  }

  copyVirtualAccount(value: string) {
    copy(value);
    if (copy(value)) {
      const x: any = document.getElementById("snackbar");
      x.className = "show";
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
  
  get activeCashback() {
    return AccountController.activeCashback;
  }

  get isAnyCashbackConfigActive() {
    return AccountController.accountData.isPosAccount && this.activeCashback.data.ccbId;
  }

}
