
import {
  formatDateWithoutTime,
  formatPrice,
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { Vue } from "vue-class-component";

export default class ProgramCashbackBanner extends Vue {
  get activeCashback() {
    return AccountController.activeCashback;
  }
  get activeTopup() {
    return AccountController.activeTopupFee;
  }
  get formatTopupFee() {
    return formatPrice(this.activeTopup.data.topupFee);
  }
  get startDateCashback() {
    return formatDateWithoutTime(
      new Date(this.activeCashback.data.ccbStartDate)
    );
  }
  get dataTiers() {
    return this.activeCashback.data.ccbTiers;
  }
  get endDateCashback() {
    return formatDateWithoutTime(new Date(this.activeCashback.data.ccbEndDate));
  }

  setShowModalDetailCashback(val: boolean) {
    BalanceController.setShowModalDetailCashback(val);
  }

  get isAnyCashbackConfigActive() {
    return (
      AccountController.accountData.isPosAccount &&
      this.activeCashback.data.ccbId
    );
  }

  get isAnyTopupFeeConfigActive() {
    return (
      AccountController.accountData.isPosAccount &&
      this.activeTopup.data.topupFeeId
    );
  }
}
